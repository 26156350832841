import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ReactTooltip        from "react-tooltip";

import ModalButtons from "../shared/modal/ModalButtons";
import {hideModalForm, serializeFormToObject} from "../../utils/misc";
import {calculateTotalPay, createPayment, makePostRequest} from "../../utils/api";
import {setList, setLoadSpinner} from "../../storage/global";
import InputText from "../input/InputText";
import {PROMOCODE} from "../../constants";
import InfoQuestion from "../shared/InfoQuestion";

const PayParcelsForm = ({parcels}) => {

    const [refCheck, setRefCheck] = useState({});

    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const list = useSelector(state => state.global.list);

    const [payTotal, setPayTotal] = useState(0)
    const [promocode, setPromocode] = useState('')
    const [promocodeIsValid, setPromocodeIsValid] = useState(false)
    const [promocodeError, setPromocodeError] = useState('')
    const [promocodeData, setPromocodeData] = useState(null)

    useMemo(() => {
        calculate()
    }, [parcels])

    const discount = useMemo(() => {
        if (parcels.length === 1 && !!payTotal) {
            // const totalSum = parcels.reduce((total, item, index, array) => total + Number(item.sum), 0);
            const totalSum = Number(parcels[0].sum) - payTotal;
            return !!totalSum && '-' + totalSum;
        }
        return '';
    }, [promocodeData, payTotal])

    async function calculate(promocodeData = {}) {
        //request
        calculateTotalPay({parcels, promocodeData}).then(({data}) => {
            setPayTotal(data.total)
        })
    }

    function resetVal(statusText, valid = false, promocodeData = null) {
        setPromocodeError(statusText);
        setPromocodeIsValid(valid);
        setPromocodeData(promocodeData);
        calculate(promocodeData)
    }

    async function checkPromocode() {
        if(parcels[0].country) {
            const params = {
                lang: i18n.language === 'cn' ? 'zh' : 'en',
                promocode,
                country: parcels[0].country,
                // shipments: list.filter(parcel => parcel.check === true).map(parcel => parcel.uid)
                barcode: parcels[0].code,
            };
            const res = await makePostRequest('/api/misc/CheckRelevancePromocodeChina', params, {}).catch((error) => {
                console.log("CheckRelevancePromocodeChina error:", error);
            })
            if(res.status) {
                if(res.status !== 200) {
                    resetVal(res.status + ': ' + res.statusText)
                } else {
                    if(res.data?.isError) {
                        resetVal(res.data?.ErrorText)
                    } else {
                        resetVal('', true, res.data.ResultData)
                    }
                }
            } else {
                if(res.response?.status !== 200) {
                    resetVal(res.response.status + ': ' + res.response.statusText)
                } else {
                    if(!res.response?.data || res.response?.data?.isError) {
                        resetVal(res.response.data?.ErrorText)
                    }
                }
            }
        }
    }

    function handleSubmit(e) {

        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        const requestData = serializeFormToObject(form)

        dispatch(setLoadSpinner(true))

        createPayment({
            parcels,
            promocode: promocodeIsValid ? promocode : '',
            promocodeData
        })
            .then((response) => {
                const div = document.createElement('div');
                document.body.appendChild(div)
                div.innerHTML = response.data;
                div.id = 'alipay';
                document.getElementById('alipaySubmit').setAttribute('target', '_blank')
                div.querySelector('form').submit()
                div.remove();

                hideForm();
                dispatch(setList(list.map(parcel => {
                    parcel.check = false;
                    return parcel;
                })))
            })
            .finally(() => dispatch(setLoadSpinner(false)))

    }

    function hideForm() {
        hideModalForm(dispatch)()
    }

    function handleHover(event, ref, isHover = true) {
        if (isHover)
            ReactTooltip.show(ref)
        else
            ReactTooltip.hide(ref)
    }

    return (
        <Form noValidate onSubmit={handleSubmit} className={''}>
            <table className={'mb-4'} width={'100%'}>
                <tbody>
                    {parcels.map((parcel, key) =>
                        <tr key={key}>
                            <td>
                                {key + 1}.
                            </td>
                            <td className={'d-flex align-items-start'}>{parcel.code}</td>
                            <td className={''} width={''}>
                                {parcel.sum}
                            </td>
                        </tr>
                    )}

                    {!!discount && <tr style={{height: '36px'}}>
                        <td><strong>{t('Discount_Amount')}:</strong></td>
                        <td></td>
                        <td><strong>{discount}</strong></td>
                    </tr>}
                    <tr>
                        <td><strong>Total Pay:</strong></td>
                        <td></td>
                        <td><strong>{payTotal.toFixed(2)}</strong></td>
                    </tr>
                    {parcels.length === 1 && <tr>
                        <td><strong>Promo code:</strong></td>
                        <td>
                            <InputText
                                id={PROMOCODE}
                                placeholder={t('PromoCodeOnly')}
                                groupClssses={'mx-3'}
                                handleChange={(value) => {
                                    setPromocode(value);
                                    setPromocodeError('');
                                    setPromocodeIsValid(false);
                                    promocodeIsValid && calculate();
                                }}
                                handleBlur={(value) => setPromocode(value)}
                                errorMessage={promocodeError}
                                value={promocode}
                                validations={null}
                                errorStyles={promocodeError ? {
                                    position: 'relative',
                                    maxWidth: '200px',
                                } : {}}
                                styles={{
                                    minWidth: '250px'
                                }}
                            />
                        </td>
                        <td><strong>
                            {promocode && <button
                                    ref={ref => setRefCheck(ref)}
                                    onMouseOver={event =>  !promocodeIsValid && handleHover(event, refCheck)}
                                    onMouseOut={event => handleHover(event, refCheck, false)}
                                    className={`btn btn-simple btn-simple-circle ${promocodeIsValid ? 'col-bg-add' : 'bgcol-figma-highlight cursor-pointer'}`}
                                    disabled={false}
                                    onClick={ (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        checkPromocode()
                                    }}
                                    data-tip={t('Check')}
                                >
                                    {promocodeIsValid ? <i className={`fas fa-check`} /> : t('Apply')}
                                </button>}
                        </strong></td>
                    </tr>}
                </tbody>
            </table>
            <ModalButtons buttons={{
                cancel: {
                    text: t('cancel'),
                    variant: 'outline-primary',
                    type: 'button',
                    action: hideModalForm(dispatch)
                },
                save: {
                    disabled: promocode && !promocodeIsValid,
                    text: t('pay'),
                    variant: 'primary',
                    type: 'submit'
                }
            }}/>
        </Form>
    );
};

export default PayParcelsForm;
