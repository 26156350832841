import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector }                  from "react-redux";
import { useTranslation }                            from "react-i18next";
import moment                                        from "moment/moment";

import {
    setAlertShow, setCurrentPage,
    setList,
    setLoadSpinner,
    setModalForm,
    setSearch,
    setSelectedList,
    setTokenInfo,
    setGroupSearchesCheckboxes
}                                                    from "../../storage/global";
import {
    deleteNewParcels,
    exportNewParcels,
    getNewParcels,
    getParcelInfo,
}                                                    from "../../utils/api";
import {
    goTrackingSystem,
    requestDeleteMethod,
    viewAlert,
    getList,
}                                                    from "../../utils/misc";
import {
    CODE,
    COMMODITY,
    CONSIGNEE,
    DATE,
    DESC,
    QTY, routesNames, SELECTED,
    SEND_BY,
    TELEPHONE,
    COMENT,
    TO_COUNTRY,
    TRACK_NUMBER,
    TRACKER,
    SERVICE_TYPE,
    WEIGHT
}                              from "../../constants";

import ActionBlock                        from "./ActionBlock";
import ImportForm                         from "../../components/forms/ImportForm";
import NewParcelForm                      from "../../components/forms/NewParcelForm";
import PrintSettingsForm                  from "../../components/forms/PrintSettingsForm";
import DeleteParcelsForm                  from "../../components/forms/DeleteParcelsForm";
import CheckBox                           from "../../components/input/CheckBox";
import PageMenu                           from "../../components/shared/PageMenu";
import CheckboxList                       from "../../components/shared/CheckboxList";
import PageListTemplate                   from "../../components/shared/PageListTemplate";
import EditButton                         from "../../components/shared/pages_buttons/EditButton";
import DeleteButton                       from "../../components/shared/pages_buttons/DeleteButton";
import PrintStickerButton                 from "../../components/shared/pages_buttons/PrintStickerButton";
import ExportButton                       from "../../components/shared/pages_buttons/ExportButton";
import ImportButton                       from "../../components/shared/pages_buttons/ImportButton";
import ButtonStyleAction                  from "../../components/shared/pages_buttons/ButtonStyleAction";

const NewParcels = () => {

    const date = moment();
    const filename = `new_parcels_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const list = useSelector(state => state.global.list);
    const groupSearchesCheckboxes = useSelector(state => state.global.groupSearchesCheckboxes.newParcels);
    const withGroupFilter = useSelector(state => state.global.groupSearchesCheckboxes.withGroupFilter);

    const [checkedAll, setCheckedAll] = useState(false);
    const [listAfterFilter, setListAfterFilter] = useState([]);

    const groupSearchesCheckboxesCodes = useMemo(() => Object.keys(groupSearchesCheckboxes).filter( code => groupSearchesCheckboxes[code]), [groupSearchesCheckboxes])

    const order = {
        direction: DESC,
        name: DATE
    };

    const deleteFormData = {
        title: t('delete_selected_parcels'),
        form: <DeleteParcelsForm
            deleteMethod={requestDeleteMethod(dispatch, () => getList(dispatch, t), deleteNewParcels, t)}
            text={t('delete_parcels_text_1')}
        />,
    }

    // //Clear before destroy component
    useEffect(() => () => {
        dispatch(setModalForm({show: false}))
        dispatch(setAlertShow(false))
        dispatch(setSearch(''))
        dispatch(setList([]))
        dispatch(setSelectedList([]))
        dispatch(setCurrentPage(routesNames.NEW_PARCELS))
    }, [])

    useMemo(() => {
        const isCheck:boolean = !!list.filter(parcel => parcel.check).length
        setCheckedAll(isCheck)
    }, [list])


    function handleSearch(search) {
        dispatch(setSelectedList([]))
    }

    function handleExport() {
        let sendList = [...list];
        sendList = sendList.filter(parcel => parcel.check)
        sendList.forEach( el => {
            let service_type = el.service_type
            if(el.service_type === "0") service_type = 'TO DOOR';
            if(el.service_type === "1") service_type = 'PUDO';
            el.service_type = service_type;
        })
        return exportNewParcels(sendList)
    }

    function openGroupSearch() {
      dispatch(setModalForm({
          title: t('GroupSearchMenu'),
          form: <CheckboxList list={groupSearchesCheckboxes} setList={saveAndApplyGroupSearch}/>
      }))
    }

    function saveAndApplyGroupSearch(list) {
      dispatch(setGroupSearchesCheckboxes({
          page: 'newParcels',
          val: list
      }))
    }

    function handlePrint() {
        dispatch(setModalForm({
            title: t('printSettings'),
            form: <PrintSettingsForm/>,
            fullScreen: true,
        }))
    }

    function handleAddNew() {
        dispatch(setModalForm({
            title: '',
            form: <NewParcelForm getListParcels={() => getList(dispatch, t)}/>,
            fullScreen: true,
            hideOut: false,
        }))
    }

    function handleEdit(parcel) {

        const uid = parcel.uid;

        dispatch(setLoadSpinner(true))
        //makeRequest
        getParcelInfo({uid})
            .then(({data}) => {
                dispatch(setModalForm({
                    title: '',
                    form: <NewParcelForm data={{...parcel, ...data.item}} isEdit getListParcels={() => getList(dispatch, t)}/>,
                    fullScreen: true,
                    hideOut: false,
                }))
            })
            .catch(({response}) => {
                viewAlert(dispatch, response, {title: t('error')})
            })
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function handleDoubleClick(event, parcel, ref) {
        handleEdit(parcel)
    }

    function handleAllChek(value) {
        const newList = [...list];
        (listAfterFilter && listAfterFilter.length ? listAfterFilter : list).forEach(parcel => {
            const numEl = list.findIndex( el => el.uid === parcel.uid)
            if (numEl >= 0) newList[numEl].check = value;
        });
        dispatch(setList(newList));
        setCheckedAll(value)
    }

    function selectAll() {
        handleAllChek(!checkedAll)
    }

    function handleCheck(value, item, ref) {
        dispatch(setList(list.map(parcel => {
            if (item.uid === parcel.uid) {
                parcel.check = !parcel.check;

            }
            return parcel;
        })));
    }

    const listHead = [
        {
            name: SELECTED, col: 1, text: '+', sortArrow: false, getChildren: (v, k) => {
                return <CheckBox
                    id={'all-check'}
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={handleAllChek}
                    value={checkedAll}
                />
            }
        },
        {name: TRACK_NUMBER, col: 3, text: t('track_number')},
        {name: DATE, col: 2, text: t('date_of_creating')},
        {name: COMMODITY, col: 2, text: t('commodity')},
        {name: TRACKER, col: 3, text: t('tracking')},
        {name: SERVICE_TYPE, col: 2, text: t('TypeDelivery')},
        // {name: QTY, col: 1, text: t('qty_2')},
        {name: WEIGHT, col: 2, text: t('weight')},
        // {name: PRICE, col: 1, text: t('value')},
        {name: SEND_BY, col: 2, text: t('ship')},
        {name: TO_COUNTRY, col: 2, text: t('to')},
        {name: CONSIGNEE, col: 2, text: t('consignee')},
        // {name: ADDRESS, col: 2, text: t('address')},
        // {name: SENSITIVE_GOODS, col: 2, text: t('sn_goods')},
        // {name: TELEPHONE, col: 3, text: t('telephone')},
        {name: COMENT, col: 3, text: t('comment')},
    ]

    const listItem = [
        {
            name: SELECTED, col: 1, isTrackNumber: false,
            getChildren: (parcel) => {
                return <CheckBox
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                    value={!!parcel.check}
                />
            }
        },
        {
            name: CODE, col: 3, isTrackNumber: true,
            click: (event, parcel, ref) => {goTrackingSystem(parcel.code)}, attr: {"no-select": 'no-select'},
            copy: true,
            classTableItem: 'cursor-pointer'
        },
        {name: DATE, col: 2, isTrackNumber: false},
        {name: COMMODITY, col: 2, isTrackNumber: false},
        {name: TRACKER, col: 3, isTrackNumber: false, afterLoadData: true},
        {
            name: SERVICE_TYPE,
            col: 2,
            isTrackNumber: false,
            getChildren: (parcel) => {
                if(parcel.service_type === "0") return t('toDoor')
                if(parcel.service_type === "1") return t('PUDO')
                return t('error')
            }
        },
        // {name: QTY, col: 1, isTrackNumber: false},
        {name: WEIGHT, col: 2, isTrackNumber: false},
        // {name: PRICE, col: 1, isTrackNumber: false},
        {name: SEND_BY, col: 2, isTrackNumber: false},
        {name: TO_COUNTRY, col: 2, isTrackNumber: false},
        {name: CONSIGNEE, col: 2, isTrackNumber: false},
        // {name: ADDRESS, col: 2, isTrackNumber: false},
        // {name: SENSITIVE_GOODS, col: 2, isTrackNumber: false},
        // {name: TELEPHONE, col: 3, isTrackNumber: false},
        {name: COMENT, col: 3, isTrackNumber: false},
    ]

    const listItemMobile = {
        firstColumn: [
            {
                name: CODE, nameColumn: t('track_number'), col: 24, isTrackNumber: true,
                click: (event, parcel, ref) => {goTrackingSystem(parcel.code)}, attr: {"no-select": 'no-select'},
                copy: true
            },
            {name: COMMODITY, nameColumn: t('commodity'), col: 24, isTrackNumber: false},
            {name: TO_COUNTRY, nameColumn: t('to'), col: 24, isTrackNumber: false},
            {name: TRACKER, nameColumn: t('tracking'), col: 24, isTrackNumber: false, afterLoadData: true},
        ],
        secondColumn: [
            {name: DATE, nameColumn: t('date_of_creating'), col: 14, isTrackNumber: false},
            {
                name: SELECTED, nameColumn: t(''), col: 10, isTrackNumber: false, classTableItem: 'w-100',
                getChildren: (parcel) => {
                    return <div className={'d-flex justify-content-end'}>
                        <CheckBox
                            className={'check-input'}
                            styles={{fontSize: '20px'}}
                            handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                            value={!!parcel.check}
                        />
                    </div>
                }
            },
            {name: QTY, nameColumn: t('qty_2'), col: 8, isTrackNumber: false},
            {name: WEIGHT, nameColumn: t('weight'), col: 8, isTrackNumber: false},
            {name: SEND_BY, nameColumn: t('ship'), col: 8, isTrackNumber: false},
            {name: CONSIGNEE, nameColumn: t('consignee'), col: 24, isTrackNumber: false},
            {
                name: SERVICE_TYPE,
                nameColumn: t('TypeDelivery'),
                col: 24,
                isTrackNumber: false,
                getChildren: (parcel) => {
                    if(parcel.service_type === "0") return <div className={``}>
                        {t('toDoor')}
                    </div>
                    if(parcel.service_type === "1") return <div className={``}>
                        {t('PUDO')}
                    </div>
                    return <div className={``}>
                    {t('error')}
                </div>
                }
            },
        ]
    }

    function setDataAfterFilter(data) {
        setListAfterFilter(data)
    }

    return (
        <div className={'container-page h-100'}>
            <ActionBlock
                handleSearch={handleSearch}
                deleteFormData={deleteFormData}
                handleExport={handleExport}
                openGroupSearch={openGroupSearch}
                exportFilename={filename}
                handlePrint={handlePrint}
                handleAddNew={handleAddNew}
                handleEdit={handleEdit}
                handle={selectAll}
                isSelectedAll={checkedAll}
            />
            <PageListTemplate
                srcOrder={order}
                // classesRow={'cursor-pointer'}
                uniqueRowId={'uid'}
                listHead={listHead}
                listItem={listItem}
                listItemMobile={listItemMobile}
                emptyListText={t('dontHaveAnyParcel')}
                disableSelect
                getDataMethod={() => getList(dispatch, t)}
                handleDoubleClick={handleDoubleClick}
                listForFilter={withGroupFilter ? groupSearchesCheckboxesCodes : null}
                orderedHook={ data => setDataAfterFilter(data)}
            />
            <PageMenu stylesMenuList={{width: '13rem'}}>
                <EditButton handle={handleEdit}/>
                <DeleteButton deleteFormData={deleteFormData}/>
                <PrintStickerButton handle={handlePrint}/>
                <ExportButton handle={handleExport} filename={filename}/>
                <ButtonStyleAction handle={openGroupSearch} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
            </PageMenu>
        </div>
    )
}

export default memo(NewParcels);
