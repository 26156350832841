export const DEFAULT_ERROR_OBJECT = {
    isError: false,
    message: ''
}

export const routesNames = {
    HOME: '/',
    AUTH: 'auth',
    LOGIN: 'login',
    SIGNUP: 'signup',
    RESTORE: 'restore',
    DOCS: 'docs',
    FAQ: 'faq',
    API: 'api',
    APP: 'app',
    USER: 'user',
    BALANCE: 'balance',
    INFO: 'info',
    ALIPAY: 'alipay',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    RECEIVE: 'receive',
    MISC: 'misc',
    SEND_TO_WAREHOUSE: 'send-to-warehouse',
    CHANGE_TRACK_NUMBER: 'change-track-number',
    NEW_PARCELS: 'new-parcels',
    WAITING_ARRIVAL: 'waiting-arrival',
    SENT_PARCELS: 'sent-parcels',
    UNIDENTIFIED_PARCELS: 'unidentified-parcels',
    PROMO_CODES: 'promo-codes',
    ACCOUNTING: 'accounting',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
    GET_LIST: 'get-list',
    GET_INFO: 'get-info',
    ADMIN: 'admin',
    SYSTEM_SETTINGS: 'admin/system-settings',
    COUNTRY_SETTINGS: 'admin/country-settings',
    POLL_SETTINGS: 'admin/poll-settings',
    RESPOSIBLE_EMAILS: 'admin/resposible-emails',
    PROFILE_SETTINGS: 'admin/profile-settings',
    NOTIFICATION_SETTINGS: 'admin/notification-settings',
    LIST_LOGS: 'admin/list-logs',
    REQUEST_SETTINGS: 'admin/request-settings',
    REQUEST_SETTINGS_LIST: 'admin/request-settings/request-settings-list',
    REQUEST_SETTINGS_STATISTIC: 'admin/request-settings/request-settings-statistic',
    EMAILS_FOR_WARNINGS: 'admin/request-settings/emails-for-warnings',
    QUERY_BY_DATE: 'admin/request-settings/query-by-date',
    USER_METRICKS: 'admin/request-settings/user-metricks',
    FEEDBACK: 'admin/feedback',
    AB_TEST: 'admin/ab-test',
    AB_TEST_SETTINGS: 'admin/ab-test/settings',
    AB_TEST_LIST: 'admin/ab-test/list',
    COMPONENTS: 'components'
}

export const MENU_TYPE = {
    SUBMENU: 'submenu',
    ITEM: 'item',
    ITEM_GROUP: 'item_group',
    DIVIDER: 'divider'
}

export const DESC = 'desc';
export const ASC = 'asc';
export const CHINA_TRACK = 'china_track'
export const TRACK_NUMBER = 'tracknum'
export const TrackNumber = 'TrackNumber'
export const Data = 'Data'
export const BARCODE = 'barcode'
export const SEND_DATE = 'senddate'
export const SENT_DATE = 'sentdate'
export const QTY = 'quantity'
export const CONSIGNEE = 'reciver'
export const SEND_BY = 'ship'
export const TO_COUNTRY = 'to'
export const UID = 'uid'
export const PRICE = 'value'
export const WEIGHT = 'weight'
export const Weight = 'Weight'
export const COMMODITY = 'Commodity'
export const ESTIMATED_DELIVERY = 'EstimatedDelivery'
export const WAREHOUSE = 'WH'
export const WAREHOUSE_FULL = 'warehouse'
export const TRACK = 'tracks'
export const CNTS = 'cnts'
export const PHONE = 'phone'
export const PHONE_NUMBER = 'phone_number'
export const ADDRESS = 'address'
export const FCOST = 'fCost'
export const COMMENT = 'comment'
export const CODE = 'code'
export const DATE = 'date'
export const SENSITIVE_GOODS = 'sensitive_goods'
export const TELEPHONE = 'tel'
export const COMENT = 'coment'
export const PARSE_VALUE = 'parselvalue'
export const PARCEL_NUMBER = 'object'
export const OPERATION = 'operation'
export const SUM = 'debt'
export const SUM_MINUS = 'credit'
export const EMAIL = 'email'
export const PASSWORD = 'password'
export const PASSWORD_AGAIN = 'password_again'
export const PASSWORD_CONFIRM = 'password_confirmation'
export const DOCUMENTS_IMAGES = 'documentsImages'
export const IC_CRD_NUM = 'ic_crd_num'
export const INPUT_CAPTCHA = 'input_captcha'
export const COMPANY_NAME = 'company_name'
export const CONTACT_NAME = 'contact_name'
export const WECHAT_ID = 'wechat_id'
export const NAME = 'name'
export const CITY = 'city';
export const COUNTRY = 'country';
export const SELECTED = 'selected';
export const HS_CODE = 'hs_code';
export const IOSS_NUMBER = 'IOSS_Number';
export const INSURANCE_VALUE = 'insurance_value';
export const PASSPORT = 'Passport';
export const PASSPORT_NUMBER = 'passport_num';
export const REGION = 'region';
export const SKU = 'sku';
export const TAX_ID_NUMBER = 'TaxIdNumber';
export const ID_DEP = 'id_dep';
export const PARCEL_VALUE = 'parcelvalue';
export const PB = 'pb';
export const RECEIVER = 'reciver';
export const SUM_2 = 'sum';
export const URL = 'url';
export const ASSOCIATED_BARCODES = 'associated_barcodes';
export const ZIP = 'zip';
export const GOODS_TYPE = 'goods_type';
export const MANAGER = 'manager';
export const BIRTHDAY = 'birthday';
export const CHINASHOP = 1;
export const CONSOLE = 2;
export const REGISTER_NAME = 'register_name'
export const LOCAL_TRACK_NUMBER = 'local_track_number'
export const TRACKER = 'tracker'
export const SERVICE_TYPE  = 'service_type'
export const CAPTCHA_SWITCH = 'captcha-switch'
export const USER_PHONE = 'userPhone'
export const RECIPIENT_PHONE = 'recipientPhone'
export const POLL_NAME = 'pollName'
export const POLL_DATEFROM = 'pollDateFrom'
export const POLL_DATETO = 'pollDateTo'
export const POLL_DATE_FROM = 'date_from'
export const POLL_DATE_TO = 'date_to'
export const EDIT = 'edit'
export const SAVE = 'save'
export const ACTIVE = 'active'
export const DELETE = 'delete'
export const CLEAR_CACH = 'clear_cach'
export const EMAILS = 'Emails'
export const PHONE_VERIFY = 'phone_verify'
export const CREATED_REQUEST = 'created_request'
export const NAME_METRICS = 'name_metrics'
export const VALUE = 'value'
export const PERCENTAGE = 'percentage'
export const CLIENT_ID = 'client_id'
export const REQ_METHOD = 'req_method'
export const REQ_URI = 'req_uri'
export const REQUEST_RATE = 'request_rate'
export const AVERAGE_REQUEST_TIME = 'requestAvgTime'
export const WARNING_NOTIF_MAX_VALUE = 'warningNotifMaxValue'
export const PROMOCODE = 'promocode'
export const COUNT = 'count'
export const CAB_UNIQUE = 'cab_unique'
export const COUNT_CAB = 'count_cab'
export const SITE_UNIQUE = 'site_unique'
export const COUNT_SITE = 'count_site'
export const Promo_code_number = 'promocodeNum'
export const Promo_code_amount = 'Promo_code_amount'
export const STARTDATE = 'startDate'
export const FINISHDATE = 'finishDate'
export const The_country_for_which_it_operates = 'country'
