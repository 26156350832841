import React, { useMemo }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation }           from "react-i18next";

import ButtonSmallNoBorder          from "../../components/shared/ButtonSmallNoBorder";
import { viewAlert }                from "../../utils/misc";

const СreateNewParcel = ({className, handle}) => {

    const {t} = useTranslation();

    const dispatch = useDispatch();

    function onClick(event) {
        if (typeof handle === 'function') handle();
    }

    return (
        <div onClick={onClick} className={`${className ? className : ''}`}>
            <ButtonSmallNoBorder pClasses={'p-0 p-md-1'} classNames={'mobile-fs-11px-lh-15px-impot'}>
                <img className={'mr-1'} src="/assets/img/plus-circle.svg" alt="Сreate new parcel"/>
                <span>{t('Сreate_new_parcel')}</span>
            </ButtonSmallNoBorder>
        </div>
    );
};

export default СreateNewParcel;
