import React, { memo, useEffect, useMemo, useState }         from 'react';
import { useDispatch, useSelector }                          from "react-redux";
import ReactList                                             from 'react-list';
import { useLocation }                                       from "react-router-dom";
import ReactTooltip                                          from "react-tooltip";

import HeadList                                              from "./HeadList";
import HeadList2                                             from "./HeadList2";
import ItemHeadList                                          from "./ItemHeadList";
import ItemHeadList2                                          from "./ItemHeadList2";
import RowList                                               from "./RowList";
import ItemList                                              from "./ItemList";
import NothingPage                                           from "./NothingPage";
import {
    addSelectedParcelList,
    removeSelectedParcelList,
    setLoadSpinner,
    SET_LOAD_SPINNER_RACCOON,
}                                   from "../../storage/global";
import { ASC, DATE, SENT_DATE }     from "../../constants";
import { getDirection, sortedList, viewAlert } from "../../utils/misc";

const PageListTemplate = ({
                              classesRow,
                              listHead,
                              listItem,
                              listItemMobile,
                              emptyListText,
                              disableSelect,
                              uniqueRowId,
                              srcOrder,
                              getDataMethod,
                              renderItemHook,
                              orderedHook,
                              handleClick,
                              handleDoubleClick,
                              headList2,
                              listForFilter
                          }) => {

    const dispatch = useDispatch();

    const isMobile = useSelector(state => state.global.isMobile);
    const selectedList = useSelector(state => state.global.selectedList);
    const search = useSelector(state => state.global.search);
    const list = useSelector(state => state.global.list);

    const defStorage = {
        items: [],
        page: 1,
        iop: 50,
        order: {
            name: '',
            direction: ASC
        },
    };

    const [storage, setStorage] = useState(defStorage)

    const {items, order} = storage;

    useEffect(() => {
        setStorage({
            ...storage,
            order: {...srcOrder}
        })
        if (typeof getDataMethod === 'function') {
            dispatch(setLoadSpinner(true));
            dispatch({type: SET_LOAD_SPINNER_RACCOON, payload: true});
            getDataMethod()
                .then(response => {
                    // dispatch(setList(response.data))
                })
                .catch((response) => {
                    viewAlert(dispatch, response.response)
                })
                .finally(() => {
                    dispatch(setLoadSpinner(false));
                    dispatch({type: SET_LOAD_SPINNER_RACCOON, payload: false});
                })
        }
    }, [])

    useMemo(() => {
        ordering(order.name, false)
    }, [list, search, listForFilter])

    const IDList = useMemo(() => list.map(value => value[uniqueRowId]), [list])
    const selectedIDList = useMemo(() => selectedList.map(value => value[uniqueRowId]), [selectedList])

    const stylePageBody = {}
    if (/(waiting-arrival)/ig.test(useLocation().pathname))
        stylePageBody.height = 'calc(100% - 106px)'

    function clickRow(event, item, ref) {
        if (typeof handleClick === 'function')
            handleClick(event, item, ref)
        //no-select атрибут нужен, чтоб не выделялись рядки когда нажимаешь на номер посылки. Клик по номеру открывает
        // трекинг.
        if (!event.target.getAttribute('no-select')) {
            if (!disableSelect) {
                if (isSelected(item))
                    dispatch(removeSelectedParcelList(item))
                else
                    dispatch(addSelectedParcelList(item))
            }
        }
    }

    function doubleClickRow(event, item, ref) {
        //no-select атрибут нужен, чтоб не выделялись рядки когда нажимаешь на номер посылки. Клик по номеру открывает
        // трекинг.
        if (!event.target.getAttribute('no-select')) {
            if (typeof handleDoubleClick === 'function')
                handleDoubleClick(event, item, ref)
        }
    }

    function clickHead(name) {
        return () => ordering(name);
    }

    function isSelected(item) {
        return !!selectedIDList.includes(item[uniqueRowId])
    }

    function handleHover(event, ref, isHover = true) {
        if (isHover)
            ReactTooltip.show(ref)
        else
            ReactTooltip.hide(ref)
    }

    function ordering(column, switchDirection) {
        handleOrder(order, [DATE, SENT_DATE].includes(column), column, switchDirection)
    }

    function handleOrder({name, direction}, isDate, column, switchDirection = true ) {

        direction = switchDirection ? getDirection(order, column) : direction;

        return sortedList(list, column, direction, search, isDate, listForFilter)
            .then((data => {
                // console.log('sortedList: ', {...storage, items: data, order: {name: column, direction}});
                setStorage({...storage, items: data, order: {name: column, direction}})
                return data
            }))
            .then(data => {
                if (orderedHook && typeof orderedHook === "function")
                    orderedHook(data)
            })
    }

    return (
        <>
            {headList2 ? <HeadList2 className={'d-mobile-none'}>
                {
                    listHead.map((v, k) =>
                        <ItemHeadList2
                            key={k}
                            name={v.name}
                            col={v.col}
                            text={v.text}
                            onClick={clickHead(v.name)}
                            order={order}
                            sortArrow={v.sortArrow}
                            classNames={v.classNames}
                        >{v.getChildren ? v.getChildren(v, k) : null}</ItemHeadList2>)
                }
            </HeadList2>
            : <HeadList className={'d-mobile-none'}>
                {
                    listHead.map((v, k) =>
                        <ItemHeadList
                            key={k}
                            name={v.name}
                            col={v.col}
                            text={v.text}
                            onClick={clickHead(v.name)}
                            order={order}
                            sortArrow={v.sortArrow}
                        >{v.getChildren ? v.getChildren(v, k) : null}</ItemHeadList>)
                }
            </HeadList>
            }

            <div className={'page-body'} style={{...stylePageBody}}>
                <ReactList
                    itemRenderer={(index, key) => {
                        const item = items[index];

                        if (renderItemHook && typeof renderItemHook === "function")
                            renderItemHook(item, index, items)

                        return <RowList disableSelect={disableSelect} item={item}
                                        className={classesRow}
                                        key={item[uniqueRowId] ?? key}
                                        uniqueId={uniqueRowId}
                                        click={clickRow}
                                        doubleClick={doubleClickRow}
                                        isSelected={isSelected(item)}
                        >
                            {
                                !isMobile
                                    ?
                                    listItem.map((value, k) => <ItemList
                                        key={(item[uniqueRowId] ?? key) + k}
                                        item={item}
                                        name={value.name}
                                        _dataField={item[value.name]}
                                        col={value.col}
                                        isTrackNumber={value.isTrackNumber}
                                        handleHover={handleHover}
                                        click={value.click}
                                        attr={value.attr}
                                        copy={value.copy}
                                        afterLoadData={value.afterLoadData}
                                        classTableItem={value.classTableItem}
                                        classNames={value.classNames}
                                        classNamesChildren={value.classNamesChildren}
                                    >{value.getChildren ? value.getChildren(item) : null}</ItemList>)
                                    :
                                    <>
                                        <div className={'col-6 p-2'}>
                                            <div className={'row g-0'}>
                                                {
                                                    listItemMobile.firstColumn.map((value, k) => <ItemList
                                                        key={(item[uniqueRowId] ?? key) + k}
                                                        item={item}
                                                        name={value.name}
                                                        _dataField={item[value.name]}
                                                        nameColumn={value.nameColumn}
                                                        col={value.col}
                                                        isTrackNumber={value.isTrackNumber}
                                                        handleHover={value.handleHover}
                                                        click={value.click}
                                                        attr={value.attr}
                                                        copy={value.copy}
                                                        afterLoadData={value.afterLoadData}
                                                        classTableItem={value.classTableItem}
                                                        classNamesChildren={value.classNamesChildren}
                                                    >{value.getChildren ? value.getChildren(item) : null}</ItemList>)
                                                }
                                            </div>
                                        </div>
                                        <div className={'col-6 p-2'}>
                                            <div className={'row g-0'}>
                                                {
                                                    listItemMobile.secondColumn.map((value, k) => <ItemList
                                                        key={(item[uniqueRowId] ?? key) + k}
                                                        item={item}
                                                        name={value.name}
                                                        _dataField={item[value.name]}
                                                        nameColumn={value.nameColumn}
                                                        col={value.col}
                                                        isTrackNumber={value.isTrackNumber}
                                                        handleHover={value.handleHover}
                                                        classTableItem={value.classTableItem}
                                                    >{value.getChildren ? value.getChildren(item) : null}</ItemList>)
                                                }
                                            </div>
                                        </div>
                                    </>
                            }
                        </RowList>
                    }}
                    pageSize={50}
                    length={items.length}
                    type='variable'
                />
                {items.length ? null : <NothingPage searchLength={search.length} emptyListText={emptyListText}/>}
            </div>

            <ReactTooltip effect={'solid'} className={'my-tooltip'} delayHide={100}/>
            <ReactTooltip
                effect={'solid'}
                className={'my-tooltip'}
                id="my-tooltip-bottom"
                place="bottom"
            />
        </>
    );
};

export default memo(PageListTemplate);
