import React, { useMemo }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation }           from "react-i18next";

import ButtonSmallNoBorder          from "../../components/shared/ButtonSmallNoBorder";
import { viewAlert }                from "../../utils/misc";

const RefuseButton = ({className, handle}) => {

    const {t} = useTranslation();

    const dispatch = useDispatch();
    const list = useSelector(state => state.global.list)

    const count = useMemo(() => {
        return list.filter(parcel => parcel.check).length
    }, [list])

    function onClick(event) {
        if (count >= 1) {
            if (typeof handle === 'function')
                handle()
        } else {
            viewAlert(dispatch, {status: 400}, {text: t('nothingSelected'), type: 'warning'})
        }
    }

    return (
        <div onClick={onClick} className={`${className ? className : ''} ${count === 0 ? 'btn-disable' : ''}`}>
            <ButtonSmallNoBorder pClasses={'p-1'} classNames={'mobile-fs-11px-lh-15px-impot'}>
                <img className={'mr-1'} src="/assets/img/trash.svg" alt="This is not my parcel"/>
                <span>{t('This_is_not_my_parcel')}</span>
            </ButtonSmallNoBorder>
        </div>
    );
};

export default RefuseButton;
