
import React, { memo }          from 'react';
import { useTranslation }       from "react-i18next";
import { useSelector }          from "react-redux";

import СreateNewParcel              from "./СreateNewParcel";
import RefuseButton       from "./RefuseButton";
import InfoWithIcon       from "./InfoWithIcon";

const ActionBlock = (props) => {

    const {t} = useTranslation();

    return (
        <>
            {
                <div className={'p-1 justify-content-between'}>
                    <div className={'d-flex align-items-center p-0 p-md-1'}>
                        <СreateNewParcel handle={props.handleCreate} className={'ml-0 ml-md-5'} buttClassNames={""}/>
                        <RefuseButton handle={props.handleRefuse} className={'ml-1 ml-md-5'}/>
                        <InfoWithIcon
                            className={'ml-1 ml-md-5'}
                            dataClass={'unidentified-parcel'}
                            text={t('how_is_this_section_working')}
                            info={t('how_is_this_section_working_info')}
                        />
                    </div>
                </div>
            }
        </>
    )
};

export default memo(ActionBlock);
